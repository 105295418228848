<template>
    <v-container>
        <v-card
            elevation="2"
            outlined 
            class="blue lighten-4">
            <v-card-title style="padding-bottom:0px;">
                <h4 class="primary--text">{{ ehs.name }}</h4>
            </v-card-title>
            <v-card-text style="padding-bottom:10px;">
                <div v-for="(question, index) in ehs.questions" v-bind:key="question.id" :id="'ehs_container_'+question.id">
                    <div v-if="question.reviewed == 'none' || question.reviewed == 'reject'" style="margin-bottom:20px; border-bottom:1px solid #dcdcdc;padding-bottom:10px">
                    <h3 class="black--text">{{ (index+1) }}. {{ question.name }}</h3>
                    <p class="black--text" style="margin-bottom:-5px;">{{ question.description }}</p>
                    <v-text-field v-if="question.remark == 1" label="Review" :id="'review_' + question.id"></v-text-field>
                    <v-row style="margin-top:-20px !important;margin-left:1px;">
                        <v-radio-group row>
                            <v-radio label="Pass" :id="'answerPass'+question.id" color="primary" value="Pass"></v-radio>
                            <v-radio label="Fail" :id="'answerFail'+ question.id" color="primary" value="Fail"></v-radio>
                            <v-radio label="Other" :id="'answerOther'+ question.id" color="primary" value="Other"></v-radio>
                        </v-radio-group>
                    </v-row>
                    <v-row style="margin-top:-20px !important;">
                        <v-col cols="4" v-if="question.upload == 1">
                            <v-btn small @click="openCamera"><v-icon>mdi-camera</v-icon></v-btn>
                        </v-col>
                        <v-col cols="4" > <v-btn small class="green white--text" @click.once="submitQuestion(question)"><v-icon color="white">mdi-check</v-icon> Submit</v-btn> </v-col>
                        
                    </v-row>
                    </div>
                </div>
            </v-card-text>
        </v-card>      
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    name: "EhsReview",
    data:() => ({
        user_p : JSON.parse(localStorage.getItem('user')),
        upload : {
            image : []
        },
        location_pos : null
    }),
    computed:{
        //... mapGetters({ ehs : "getCurrentEhs" })
        ...mapGetters (["getCurrentProject","getCurrentEhs","getCurrentPhoto" ]),
        project () {
            return this.getCurrentProject
        },
        ehs () {
            return this.getCurrentEhs
        }
    },
    methods: {
        async submitQuestion (value) {
            let review = ""
            if(value.remark == 1) {
                review = document.getElementById("review_"+value.id).value   
            } else { review = ""}      
            let answer =""
            if(document.getElementById("answerPass"+value.id).checked){
                answer = document.getElementById("answerPass"+value.id).value
            }else if(document.getElementById("answerFail"+value.id).checked){
                answer = document.getElementById("answerFail"+value.id).value
            }else if(document.getElementById("answerOther"+value.id).checked){
                answer = document.getElementById("answerOther"+value.id).value
            }
            let formData = new FormData();
            formData.append("review",review)
            formData.append("project", this.project.id)
            formData.append("user", this.user_p.id)
            formData.append("question", value.id)
            formData.append("ehs", this.ehs.id)
            formData.append("answer", answer)
            if(value.upload == 1) {
                if(Object.keys(this.getCurrentPhoto).length === 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'An image is required'
                    })
                    return;
                }else{
                    formData.append("watermark",this.getCurrentPhoto.text)
                    formData.append("image",this.getCurrentPhoto.src)
                }
            }
            axios.post(
                "https://camusatweb.ubuniworks.com/api/v1/ehs/review",
                formData,
                { headers: {"Content-Type": "multipart/form-data"} }
            ).then((response) => {
                if(response.data.status == true){
                    Swal.fire({
                        icon: 'success',
                        title: 'EHS Checklist successfully submitted',
                        text: 'You will be informed once it has been reviewed'
                    })
                    this.$store.commit('setPhoto',{})
                    document.getElementById("ehs_container_"+value.id).style.display = "none";
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'EHS Checklist failed',
                        text: response.data.message
                    })
                }
            })
        },
        openCamera () {
            this.$router.push('/camera')
        }
    }
}
</script>